"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/Row.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _reactrouterdom = require('react-router-dom');





var _peers = require('shared/selectors/peers');
var _broadcast = require('shared/selectors/broadcast');
var _entities = require('shared/transforms/entities');
var _navigation = require('web/actions/navigation');
var _time = require('shared/services/time');
var _phone = require('shared/services/phone');

var _Checkbox = require('./components/Checkbox'); var _Checkbox2 = _interopRequireDefault(_Checkbox);
var _StatusPill = require('./components/StatusPill'); var _StatusPill2 = _interopRequireDefault(_StatusPill);
var _Avatar = require('web/components/Avatar');







const Row = ({
  patientKey,
  patient,
  message,
  isSelected,
  onToggleSelection,
}) => !patient || !message ? null : (
    _react2.default.createElement('tr', {
        className: "campaign--table--row",
        onClick: () => onToggleSelection(!isSelected), __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
    
      , _react2.default.createElement('td', { className: "campaign--table--row-checkbox", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        , _react2.default.createElement(_Checkbox2.default, {
            isChecked: isSelected,
            className: "campaign--table--record-checkbox", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        )
      )
      , _react2.default.createElement('td', { className: "campaign--table--col-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        , _react2.default.createElement(_Avatar.PatientAvatar, { size: 25, noBadge: true, patientKey: patientKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}})
        , _react2.default.createElement(_reactrouterdom.Link, {
            to: _navigation.makePatientPageRoute.call(void 0, patient.OrgKey, patientKey),
            className: "campaign--table--col-name--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        
          , _entities.makeDisplayPatientName.call(void 0, patient)
        )
      )
      , _react2.default.createElement('td', { className: "campaign--table--col-dob", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, _time.formatDisplayDate.call(void 0, patient.DateOfBirth))
      )
      , _react2.default.createElement('td', { className: "campaign--table--col-contact", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}, _phone.formatPhoneNumber.call(void 0, patient.Phone) || patient.Email)
      )
      , _react2.default.createElement('td', { className: "campaign--table--row-status", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        , _react2.default.createElement(_StatusPill2.default, { status: message.Status, desc: message.ErrorCode || null, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}})
      )
      , _react2.default.createElement('td', { className: "campaign--table--row-scheduled-at", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        , message.ScheduledAt
          ? _time.formatLongDisplayTime.call(void 0, message.ScheduledAt)
          : (['queued', 'processing'].includes(message.Status) ? 'Now' : '')
        
      )
      , _react2.default.createElement('td', { className: "campaign--table--row-sent-at", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
        , message.SentAt && _time.formatLongDisplayTime.call(void 0, message.SentAt)
      )
    )
)






const mapState = (state, { patientKey }) => ({
  message: patientKey && _broadcast.selectBroadcastMessageByKey.call(void 0, state, patientKey),
  patient: patientKey && _peers.selectPatientByKey.call(void 0, state, patientKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(Row)
