"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/Controls/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _BatchControls = require('./BatchControls'); var _BatchControls2 = _interopRequireDefault(_BatchControls);
var _AddPatients = require('./AddPatients'); var _AddPatients2 = _interopRequireDefault(_AddPatients);











const CampaignControls = ({
  campaignKey,
  isCampaignArchived,
  onStartCampaign,
  onPauseCampaign,
  onStopCampaign,
  onQueueMessages,
  onAddPatients,
}) => (
  _react2.default.createElement('div', { className: "campaign-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
    , _react2.default.createElement(_BatchControls2.default, {
      campaignKey: campaignKey,
      onStartCampaign: onStartCampaign,
      onPauseCampaign: onPauseCampaign,
      onStopCampaign: onStopCampaign,
      onQueueMessages: onQueueMessages, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
    )
    , _react2.default.createElement(_AddPatients2.default, {
      isDisabled: isCampaignArchived,
      onAddPatients: onAddPatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
    )
  )
)

exports. default = CampaignControls
