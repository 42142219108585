"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _useEntityPreload = require('shared/hooks/useEntityPreload'); var _useEntityPreload2 = _interopRequireDefault(_useEntityPreload);
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _useCampaignActions = require('./hooks/useCampaignActions'); var _useCampaignActions2 = _interopRequireDefault(_useCampaignActions);

var _EntityNotFoundScreen = require('web/screens/EntityNotFoundScreen'); var _EntityNotFoundScreen2 = _interopRequireDefault(_EntityNotFoundScreen);
var _Header = require('./Header'); var _Header2 = _interopRequireDefault(_Header);
var _Body = require('./Body'); var _Body2 = _interopRequireDefault(_Body);


var _Modals = require('web/screens/Broadcast/Modals');
var _ExportPhoneLogsModal = require('web/screens/Directory/TeamPage/ExportPhoneLogsModal'); var _ExportPhoneLogsModal2 = _interopRequireDefault(_ExportPhoneLogsModal);





const CampaignPage = ({ campaignKey }) => {
  const {
    isOpen: isEditCampaignOpen,
    onOpen: onOpenEditCampaign,
    onClose: onCloseEditCampaign,
  } = _useToggle2.default.call(void 0, )

  const {
    isOpen: isQueueMessagesModalOpen,
    onOpen: onOpenQueueMessagesModal,
    onClose: onCloseQueueMessagesModal,
  } = _useToggle2.default.call(void 0, )

  const {
    isOpen: isExportCampaignModalOpen,
    onOpen: onOpenExportCampaignModal,
    onClose: onCloseExportCampaignModal,
  } = _useToggle2.default.call(void 0, )

  const {
    onStartCampaign,
    onPauseCampaign,
    onStopCampaign,
    onUpdateCampaign,
    onAddPatients,
    onRemovePatients,
    onDequeuePatients,
    onExportCampaign,
  } = _useCampaignActions2.default.call(void 0, campaignKey)

  const loadCampaign = _react.useCallback.call(void 0, 
    () => _actions2.default.getCampaign(campaignKey),
    [campaignKey],
  )

  const hasLoadFailed = _useEntityPreload2.default.call(void 0, loadCampaign)
  if (hasLoadFailed) {
    return _react2.default.createElement(_EntityNotFoundScreen2.default, { desc: "Failed to load campaign"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}} )
  }

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Header2.default, {
        campaignKey: campaignKey,
        onEditCampaign: onOpenEditCampaign,
        onExportCampaign: onExportCampaign ? onOpenExportCampaignModal : null,
        onQueueMessages: onOpenQueueMessagesModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      )
      , _react2.default.createElement(_Body2.default, {
        campaignKey: campaignKey,
        onStartCampaign: onStartCampaign,
        onPauseCampaign: onPauseCampaign,
        onStopCampaign: onStopCampaign,
        onQueueMessages: onOpenQueueMessagesModal,
        onAddPatients: onAddPatients,
        onRemovePatients: onRemovePatients,
        onDequeuePatients: onDequeuePatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
      )
      , _react2.default.createElement(_Modals.CampaignFormModal, {
        title: "Edit campaign" ,
        submitLabel: "Save",
        campaignKey: campaignKey,
        isOpen: isEditCampaignOpen,
        onClose: onCloseEditCampaign,
        onSubmit: onUpdateCampaign, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
      )
      , _react2.default.createElement(_Modals.QueueMessagesModal, {
        isOpen: isQueueMessagesModalOpen,
        onClose: onCloseQueueMessagesModal,
        campaignKey: campaignKey,
        onStartCampaign: onStartCampaign, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
      )
      ,  onExportCampaign && isExportCampaignModalOpen && (
        _react2.default.createElement(_ExportPhoneLogsModal2.default, {
          isOpen: isExportCampaignModalOpen,
          onClose: onCloseExportCampaignModal,
          onProceed: onExportCampaign, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
        )
      )
    )
  )
}

exports. default = CampaignPage
