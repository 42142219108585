"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Modals/QueueMessages/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');







var _broadcast = require('shared/selectors/broadcast');

var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);








const QueueMessagesModal = ({
  isActive, messageDelay, notScheduledCount,
  isOpen, onClose, onStartCampaign,
}) => (
  _react2.default.createElement(_Modal2.default, {
    title: "Send messages" ,
    isOpen: isOpen,
    onClose: onClose,
    size: "xs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
  
    , _react2.default.createElement(_Form2.default, {
      onClose: onClose,
      isActive: isActive,
      messageDelay: messageDelay,
      notScheduledCount: notScheduledCount,
      onStartCampaign: onStartCampaign, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
    )
  )
)







const mapState = (state, { campaignKey }) => ({
  isActive: _broadcast.selectIsCampaignActive.call(void 0, state, campaignKey),
  messageDelay: _broadcast.selectCampaignMessageDelay.call(void 0, state, campaignKey),
  notScheduledCount: _broadcast.selectCampaignNotScheduledCount.call(void 0, state, campaignKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(QueueMessagesModal)
