"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _utils = require('shared/store/utils');
var _helpers = require('shared/actions/helpers');




















var _broadcastCampaign = require('shared/validation/broadcastCampaign');

const createCampaignActions = _utils.createAsyncActions('CreateCampaign')
const updateCampaignActions = _utils.createAsyncActions('UpdateCampaign')
const getCampaignActions = _utils.createAsyncActions('GetCampaign')
const findCampaignsActions = _utils.createAsyncActions('FindCampaigns')
const archiveCampaignActions = _utils.createAsyncActions('ArchiveCampaign')
const unarchiveCampaignActions = _utils.createAsyncActions('UnarchiveCampaign')
const findCampaignMessagesActions = _utils.createAsyncActions('FindCampaignMessages')
const addPatientsToCampaignActions = _utils.createAsyncActions('AddPatientsToCampaign')
const dequeuePatientsInCampaignActions = _utils.createAsyncActions('DequeuePatientsInCampaign')
const removePatientsFromCampaignActions = _utils.createAsyncActions('RemovePatientsFromCampaign')
const startCampaignActions = _utils.createAsyncActions('StartCampaign')
const pauseCampaignActions = _utils.createAsyncActions('PauseCampaign')
const stopCampaignActions = _utils.createAsyncActions('StopCampaign')

 const actionCreators = {
  createCampaignActions,
  updateCampaignActions,
  getCampaignActions,
  findCampaignsActions,
  archiveCampaignActions,
  unarchiveCampaignActions,
  findCampaignMessagesActions,
  addPatientsToCampaignActions,
  dequeuePatientsInCampaignActions,
  removePatientsFromCampaignActions,
  startCampaignActions,
  pauseCampaignActions,
  stopCampaignActions,
}; exports.actionCreators = actionCreators

const createCampaign = ({
  Name, TeamKey, SenderKey,
  MessageType, MessageTemplate,
  MessageDelay = _broadcastCampaign.DEFAULT_MESSAGE_DELAY_MS,
}) => _helpers.wsCommand(
  'CreateCampaign',
  createCampaignActions,
  { Name, TeamKey, SenderKey, MessageType, MessageTemplate, MessageDelay },
  null,
)

const updateCampaign = ({
  CampaignKey, Name,
  TeamKey, SenderKey,
  MessageType, MessageTemplate,
  MessageDelay = _broadcastCampaign.DEFAULT_MESSAGE_DELAY_MS,
}) => _helpers.wsCommand(
  'UpdateCampaign',
  updateCampaignActions,
  { CampaignKey, Name, TeamKey, SenderKey, MessageType, MessageTemplate, MessageDelay },
  { isOptimistic: true },
)

const getCampaign = (CampaignKey) =>
  _helpers.wsCommand('GetCampaign', getCampaignActions, { CampaignKey }, null)

const findCampaigns = (request) =>
  _helpers.wsCommand(
    'FindCampaigns',
    findCampaignsActions,
    request,
    null,
  )

const archiveCampaign = (CampaignKey) =>
  _helpers.wsCommand(
    'ArchiveCampaign',
    archiveCampaignActions,
    { CampaignKey },
    { isOptimistic: true },
  )
const unarchiveCampaign = (CampaignKey) =>
  _helpers.wsCommand(
    'UnarchiveCampaign',
    unarchiveCampaignActions,
    { CampaignKey },
    { isOptimistic: true },
  )

const findCampaignMessages = (
  CampaignKey,
  Offset,
  Limit,
  Status = null,
) =>
  _helpers.wsCommand(
    'FindCampaignMessages',
    findCampaignMessagesActions,
    { CampaignKey, Offset, Limit, Status },
    null,
  )

const addPatientsToCampaign = (CampaignKey, PatientKeys = []) =>
  _helpers.wsCommand(
    'AddPatientsToCampaign',
    addPatientsToCampaignActions,
    { CampaignKey, PatientKeys },
    { isOptimistic: true },
  )

const dequeuePatientsInCampaign = (CampaignKey, PatientKeys = []) =>
  _helpers.wsCommand(
    'DequeuePatientsInCampaign',
    dequeuePatientsInCampaignActions,
    { CampaignKey, PatientKeys },
    { isOptimistic: true },
  )

const removePatientsFromCampaign = (CampaignKey, PatientKeys = []) =>
  _helpers.wsCommand(
    'RemovePatientsFromCampaign',
    removePatientsFromCampaignActions,
    { CampaignKey, PatientKeys },
    { isOptimistic: true },
  )

const startCampaign = (CampaignKey, NumPatients, ScheduleAt) =>
  _helpers.wsCommand(
    'StartCampaign',
    startCampaignActions,
    { CampaignKey, NumPatients, ScheduleAt },
    { isOptimistic: true },
  )

const pauseCampaign = (CampaignKey) =>
  _helpers.wsCommand(
    'PauseCampaign',
    pauseCampaignActions,
    { CampaignKey },
    { isOptimistic: true },
  )

const stopCampaign = (CampaignKey) =>
  _helpers.wsCommand(
    'StopCampaign',
    stopCampaignActions,
    { CampaignKey },
    { isOptimistic: true },
  )

exports. default = {
  createCampaign,
  updateCampaign,
  getCampaign,
  findCampaigns,
  archiveCampaign,
  unarchiveCampaign,
  findCampaignMessages,
  addPatientsToCampaign,
  removePatientsFromCampaign,
  dequeuePatientsInCampaign,
  startCampaign,
  pauseCampaign,
  stopCampaign,
}
