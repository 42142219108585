"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _useSelection = require('./hooks/useSelection'); var _useSelection2 = _interopRequireDefault(_useSelection);

var _FilteredTable = require('./FilteredTable'); var _FilteredTable2 = _interopRequireDefault(_FilteredTable);
var _PaginatedTable = require('./PaginatedTable'); var _PaginatedTable2 = _interopRequireDefault(_PaginatedTable);
var _TableTopBar = require('./components/TableTopBar'); var _TableTopBar2 = _interopRequireDefault(_TableTopBar);
var _ProgressBar = require('./ProgressBar'); var _ProgressBar2 = _interopRequireDefault(_ProgressBar);
var _BulkControls = require('./BulkControls'); var _BulkControls2 = _interopRequireDefault(_BulkControls);

const keyActionTypes = [
  _actionCreators2.default.addPatientsToCampaignActions.SUCCESS.type,
  _actionCreators2.default.removePatientsFromCampaignActions.SUCCESS.type,
  _actionCreators2.default.stopCampaignActions.SUCCESS.type,
]








const MessagesTable = ({
  campaignKey,
  onAddPatients,
  onRemovePatients,
  onDequeuePatients,
}) => {
  const [activeFilter, setActiveFilter] = _react.useState(null)

  const {
    selectedRowsList,
    onSetRowSelection,
    onSetRowsBatchSelection,
    onClearAllRowsSelection,
    selectedRowsMap,
  } = _useSelection2.default.call(void 0, )

  const selectedRowsCount = selectedRowsList.length
  const hasSelectedRows = selectedRowsCount > 0

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_TableTopBar2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        ,  hasSelectedRows
          ? _react2.default.createElement(_BulkControls2.default, {
              campaignKey: campaignKey,
              selectedPatientKeys: selectedRowsList,
              onClearAllRowsSelection: onClearAllRowsSelection,
              onRemovePatients: onRemovePatients,
              onDequeuePatients: onDequeuePatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            )
          : _react2.default.createElement(_ProgressBar2.default, {
              campaignKey: campaignKey,
              activeFilter: activeFilter,
              onSetFilter: setActiveFilter, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
            )
        
      )
      ,  activeFilter
        ? _react2.default.createElement(_FilteredTable2.default, {
            filter: activeFilter,
            campaignKey: campaignKey,
            keyActionTypes: keyActionTypes,
            selectedRowsMap: selectedRowsMap,
            onSetRowSelection: onSetRowSelection,
            onSetRowsBatchSelection: onSetRowsBatchSelection,
            areAnyRowsSelected: hasSelectedRows,
            onAddPatients: onAddPatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
          )
        : _react2.default.createElement(_PaginatedTable2.default, {
            campaignKey: campaignKey,
            keyActionTypes: keyActionTypes,
            selectedRowsMap: selectedRowsMap,
            onSetRowSelection: onSetRowSelection,
            onSetRowsBatchSelection: onSetRowsBatchSelection,
            areAnyRowsSelected: hasSelectedRows,
            onAddPatients: onAddPatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
          )
      
    )
  )
}

exports. default = MessagesTable
