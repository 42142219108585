"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');








var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _user = require('shared/selectors/user');



var _broadcast = require('shared/selectors/broadcast');
var _team = require('shared/selectors/team');
var _time = require('shared/services/time');

var _noticeBar = require('web/services/context/noticeBar');
var _hooks = require('web/services/hooks');

const useCampaignActions = (campaignKey) => {
  const orgKey = _hooks.useSelector(s => _broadcast.selectCampaignOrgKey.call(void 0, s, campaignKey))
  const amIAnAdmin = _hooks.useSelector(s => !!(orgKey && _user.selectAmIAdminInOrg.call(void 0, s, orgKey)))
  const teamKey = _hooks.useSelector(s => _broadcast.selectCampaignTeamKey.call(void 0, s, campaignKey))
  const team = _hooks.useSelector(s => teamKey ? _team.selectTeamByKey.call(void 0, s, teamKey) : null)
  const teamPhoneNumber = _optionalChain([team, 'optionalAccess', _ => _.PhoneNumber])

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onStartCampaign = _react.useCallback.call(void 0, 
    (patientsCount, scheduleAt) => _actions2.default
      .startCampaign(campaignKey, patientsCount, scheduleAt)
      .catch(log.error),
    [campaignKey],
  )
  const onPauseCampaign = _react.useCallback.call(void 0, 
    () => _actions2.default
      .pauseCampaign(campaignKey)
      .catch(log.error),
    [campaignKey],
  )
  const onStopCampaign = _react.useCallback.call(void 0, 
    () => _actions2.default
      .stopCampaign(campaignKey)
      .catch(log.error),
    [campaignKey],
  )
  const onUpdateCampaign = _react.useCallback.call(void 0, 
    (campaignData) => _actions2.default
      .updateCampaign({ CampaignKey: campaignKey, ...campaignData })
      .catch(log.error),
    [campaignKey],
  )
  const handleExportCampaign = _react.useCallback.call(void 0, 
    async (FromDate, ToDate) => {
      if (!orgKey) return
      showSuccessBar('Campaign export has been requested')

      return _actions2.default.exportPhoneLogs({
        OrgKey: orgKey,
        FromNumber: teamPhoneNumber || '',
        FromDate,
        ToDate,
        TimeZone: _time.userTZ ,
      })
        .then(link => window.open(link, "_self"))
        .catch(() => showWarningBar('Failed to export campaign'))
    },
    [orgKey, teamPhoneNumber, showWarningBar, showSuccessBar],
  )
  const onExportCampaign = amIAnAdmin ? handleExportCampaign : null

  const onAddPatients = _react.useCallback.call(void 0, 
    (patientKeys) => _actions2.default.addPatientsToCampaign(campaignKey, patientKeys),
    [campaignKey],
  )
  const onRemovePatients = _react.useCallback.call(void 0, 
    (patientKeys) => _actions2.default.removePatientsFromCampaign(campaignKey, patientKeys),
    [campaignKey],
  )
  const onDequeuePatients = _react.useCallback.call(void 0, 
    (patientKeys) => _actions2.default.dequeuePatientsInCampaign(campaignKey, patientKeys),
    [campaignKey],
  )

  return {
    onStartCampaign,
    onPauseCampaign,
    onStopCampaign,
    onUpdateCampaign,
    onExportCampaign,
    onAddPatients,
    onRemovePatients,
    onDequeuePatients,
  }
}

exports. default = useCampaignActions
