"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/Controls/BatchControls.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');








var _broadcast = require('shared/selectors/broadcast');

var _Form = require('web/components/Form');









const BatchControls = ({
  isActive, isArchived, queuedCount, hasAnyPatients,
  onStartCampaign, onStopCampaign, onPauseCampaign, onQueueMessages,
}) => {
  const handleStart = () => queuedCount
    ? onStartCampaign(0, null)
    : onQueueMessages()

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', { className: "campaign-controls--batch--toggle--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        , _react2.default.createElement(_Form.Button, {
          onClick: handleStart,
          isDisabled: isArchived || isActive || !hasAnyPatients,
          className: "campaign-controls--batch--toggle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
        
          , _react2.default.createElement('i', { className: "campaign-controls--batch--toggle-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} )
          ,  isActive
            ? 'Running'
            : queuedCount ? `Resume` : `Start...`
          
        )
        ,  isActive && (
          _react2.default.createElement('div', {
            role: "button",
            onClick: onPauseCampaign,
            className: "campaign-controls--batch--toggle--pause-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          )
        )
      )
      ,  queuedCount > 0 && (
        _react2.default.createElement(_Form.Button, {
            onClick: onStopCampaign,
            className: "campaign-controls--batch--stop", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
        
          , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, "stop"), " Cancel"
        )
      )
    )
  )
}








const mapState = (state, { campaignKey }) => ({
  isActive: _broadcast.selectIsCampaignActive.call(void 0, state, campaignKey),
  isArchived: _broadcast.selectIsCampaignArchived.call(void 0, state, campaignKey),
  queuedCount: _broadcast.selectCampaignQueuedCount.call(void 0, state, campaignKey),
  hasAnyPatients: _broadcast.selectCampaignHasAnyPatientsToMessage.call(void 0, state, campaignKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(BatchControls)
